import { apiFetch, validatedApiFetch } from '@/utils/api/utils'
import type { Building } from '@/utils/types/buildings'
import { BuildingSchema } from '@/utils/types/buildings'
import { z } from 'zod'

const buildingSettableAttributes = [
  'name',
  'address',
  'zipcode',
  'city',
  'state_region',
  'country',
  'capacity',
  'threshold',
  'flex_office',
  'desks_spreadsheet_url',
  'desk_instructions',
  'enforce_security',
  'security_rules',
  'uses_guests_qr',
  'kiosk_profile_id',
  'kiosk_theme_id',
  'logo',
] as const

const BuildingListSchema = z.array(BuildingSchema)
export const listBuildings = (spaceUuid: string) =>
  validatedApiFetch({
    url: `/api/v1/spaces/${spaceUuid}/buildings.json`,
    schema: BuildingListSchema,
  })

export const listBuildingsWithProvider = (spaceUuid: string, provider: string) => {
  return apiFetch<Array<Building>>({
    url: `/api/v1/${provider}/spaces/${spaceUuid}/buildings/fetch.json`,
  })
}

export const getBuilding = (buildingId: Building['id']) =>
  validatedApiFetch({
    url: `/api/v1/buildings/${buildingId}.json`,
    schema: BuildingSchema,
  })

const makeBuildingPayload = (building: Building) => {
  let formData = new FormData()

  buildingSettableAttributes.forEach((attribute) => {
    if (building[attribute]) {
      formData.append(`building[${attribute}]`, building[attribute])
    } else if (building[attribute] === null && attribute !== 'logo') {
      formData.append(`building[${attribute}]`, '')
    }
  })

  return formData
}

export const createBuilding = (building: Building) =>
  apiFetch({
    url: `/api/v1/spaces/${useAuth().space.uuid}/buildings.json`,
    options: {
      method: 'POST',
      body: makeBuildingPayload(building),
    },
  })

export const updateBuilding = (building: Building) =>
  apiFetch({
    url: `/api/v1/buildings/${building.id}.json`,
    options: {
      method: 'PUT',
      body: makeBuildingPayload(building),
    },
  })

export const saveBuilding = (building: Building) =>
  building.id ? updateBuilding(building) : createBuilding(building)

export const getSyncDesksBuilding = (buildingId: Building['id']) =>
  apiFetch({
    url: `/api/v1/buildings/${buildingId}/sync_desks.json`,
  })
export const doSyncDesksBuilding = (buildingId: Building['id']) =>
  apiFetch({
    url: `/api/v1/buildings/${buildingId}/do_sync_desks.json`,
    options: {
      method: 'POST',
    },
  })

export const deleteBuilding = (buildingId: Building['id']) =>
  apiFetch({
    url: `/api/v1/buildings/${buildingId}.json`,
    options: {
      method: 'DELETE',
    },
  })
